import Mock from 'mockjs';
import setupMock from '@/utils/setupMock';
setupMock({
  setup: () => {
    Mock.mock(new RegExp('/api/appver/\\d+$'), 'get', () => {
      console.log('/api/appver/带参数');
      return {
        code: 0,
        data: {
          Id: 20,
          AppId: 'com.szttgk.audio',
          Code: 161,
          Name: '1.6.1',
          FilePath: '/upload/apk/czlw06jpe9k1cj2y7k.apk',
          Log: '修改TTGK的芯片logo',
          LogEn: 'Modified TTGK silicon logo',
          Type: true,
          Status: false,
          BaseUrl: 'https://walkplay.szttgk.com',
          CreateTime: '2022-01-18T09:35:55+08:00',
          UpdateTime: '2022-01-18T09:35:55+08:00'
        },
        msg: ''
      };
    });
    Mock.mock(new RegExp('/api/appver/.*'), 'put', () => {
      return {
        code: 0,
        data: {
          Id: 20,
          AppId: 'com.szttgk.audio',
          Code: 161,
          Name: '1.6.1',
          FilePath: 'app/4rGcjsfePxW.apk',
          Log: '修改TTGK的芯片logo',
          LogEn: 'Modified TTGK silicon logo',
          Type: true,
          Status: false,
          BaseUrl: '',
          CreateTime: '2022-01-18T09:35:55+08:00',
          UpdateTime: '2022-01-18T09:35:55+08:00'
        },
        msg: ''
      };
    });
  }
});