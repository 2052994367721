import Mock from 'mockjs';
import { isSSR } from '@/utils/is';
import setupMock from '@/utils/setupMock';
import { generatePermission } from '@/routes';
if (!isSSR) {
  Mock.XHR.prototype.withCredentials = true;
  setupMock({
    setup: () => {
      Mock.mock(new RegExp('/user/check-nick-name'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {}
        });
      });
      Mock.mock(new RegExp('/user/check-passport'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {}
        });
      });
      Mock.mock(new RegExp('/user/is-signed-in'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {
            OK: false
          }
        });
      });
      Mock.mock(new RegExp('/user/sign-out'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {}
        });
      });
      Mock.mock(new RegExp('/user/sign-up'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {}
        });
      });

      // 用户信息
      const userRole = window.localStorage.getItem('userRole') || 'admin';
      Mock.mock(new RegExp('/user/profile'), () => {
        return Mock.mock({
          code: 0,
          message: 'string',
          data: {
            id: 0,
            passport: 'string',
            password: 'string',
            nickname: 'string',
            createAt: 'string',
            updateAt: 'string',
            name: '王立群',
            avatar: 'https://lf1-xgcdn-tos.pstatp.com/obj/vcloud/vadmin/start.8e0e4855ee346a46ccff8ff3e24db27b.png',
            email: 'wangliqun@email.com',
            job: 'frontend',
            jobName: '前端开发工程师',
            organization: 'Frontend',
            organizationName: '前端',
            location: 'beijing',
            locationName: '北京',
            introduction: '王力群并非是一个真实存在的人。',
            personalWebsite: 'https://www.arco.design',
            verified: true,
            phoneNumber: /177[*]{6}[0-9]{2}/,
            accountId: /[a-z]{4}[-][0-9]{8}/,
            registrationTime: Mock.Random.datetime('yyyy-MM-dd HH:mm:ss'),
            permissions: generatePermission(userRole)
          }
        });
      });

      // 登录
      Mock.mock(new RegExp('/user/sign-in'), params => {
        const {
          userName,
          password
        } = JSON.parse(params.body);
        if (!userName) {
          return {
            status: 'error',
            msg: '用户名不能为空'
          };
        }
        if (!password) {
          return {
            status: 'error',
            msg: '密码不能为空'
          };
        }
        if (userName === 'admin' && password === 'admin') {
          return {
            status: 'ok'
          };
        }
        return {
          status: 'error',
          msg: '账号或者密码错误'
        };
      });
    }
  });
}