export function GetHeaderConfig() {
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('access_token')
  };
  return header;
}
export function GetReqConfig() {
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('access_token')
  };
  return header;
}