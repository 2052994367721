import Mock from 'mockjs';
import setupMock from '@/utils/setupMock';
setupMock({
  setup: () => {
    Mock.mock(new RegExp('/api/admin/login'), () => {
      return {
        code: 0,
        message: 'message',
        data: {
          LoginAccessToken: 'LoginAccessToken'
        }
      };
    });
  }
});