import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'menu.appver',
  key: 'appver',
  children: [{
    name: 'menu.appver.create',
    key: 'appver/create'
  }, {
    name: 'menu.appver.list',
    key: 'appver/list'
  }]
}, {
  name: 'menu.product',
  key: 'product',
  children: [{
    name: 'menu.product.create',
    key: 'product/create'
  }, {
    name: 'menu.product.list',
    key: 'product/list'
  }]
}, {
  name: 'menu.eq',
  key: 'eq',
  children: [
  // {
  //   name: 'menu.eq.reserved_eq',
  //   key: 'reserved_eq',
  //   children: [
  //     {
  //       name: 'menu.eq.reserved_eq.create',
  //       key: 'eq/reserved_eq/create',
  //     },
  //     {
  //       name: 'menu.eq.reserved_eq.list',
  //       key: 'eq/reserved_eq/list',
  //     },
  //   ],
  // },
  {
    name: 'menu.eq.user_eq',
    key: 'eq/user_eq/list'
  }]
}, {
  name: 'menu.sys',
  key: 'sys',
  children: [{
    name: 'menu.admin',
    key: 'admin',
    children: [{
      name: 'menu.admin.create',
      key: 'admin/create'
    }, {
      name: 'menu.admin.list',
      key: 'admin/list'
    }]
  }, {
    name: 'menu.sys.action',
    key: 'sys/action'
  }, {
    name: 'menu.sys.user',
    key: 'sys/user/list'
  }]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};
const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};
export default useRoute;