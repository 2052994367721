import Mock from 'mockjs';
import setupMock from '@/utils/setupMock';
setupMock({
  setup: () => {
    Mock.mock(new RegExp('/api/product/\\d+$'), 'get', () => {
      console.log('/api/appver/带参数');
      return {
        code: 0,
        data: {
          Id: 0,
          ProductId: '1',
          VendorId: '2',
          SerialNum: 'serial_num',
          ProductModel: 'product_model',
          Title: 'title',
          ProductBrand: 'product_brand',
          ManufactureName: 'manufacture_name',
          Description: 'description',
          ProductModelEn: 'product_modelEn',
          TitleEn: 'titleEn',
          ProductBrandEn: 'product_brandEn',
          ManufactureNameEn: 'manufacture_nameEn',
          DescriptionEn: 'descriptionEn',
          Url: 'url',
          Images: '["/upload/product/d003e9gq94der79nxi.jpeg","/upload/product/d014auzv6qtkpo9jwr.jpeg","/upload/product/d014auzvmuao68bwks.jpeg","/upload/product/d014auzzn3ywqcgkrm.jpeg"]',
          Status: 0,
          BaseUrl: 'base_url',
          CdnUrl: 'cdn_url',
          CreateTime: '2022-01-18T09:35:55+08:00',
          UpdateTime: '2022-01-18T09:35:55+08:00'
        },
        msg: ''
      };
    });
    Mock.mock(new RegExp('/api/product/\\d+$'), 'put', () => {
      return {
        code: 0,
        data: {
          Id: 0,
          ProductId: '1',
          VendorId: '2',
          SerialNum: 'serial_num',
          ProductModel: 'product_model',
          Title: 'title',
          ProductBrand: 'product_brand',
          ManufactureName: 'manufacture_name',
          Description: 'description',
          ProductModelEn: 'product_modelEn',
          TitleEn: 'titleEn',
          ProductBrandEn: 'product_brandEn',
          ManufactureNameEn: 'manufacture_nameEn',
          DescriptionEn: 'descriptionEn',
          Url: 'url',
          Images: '["/upload/product/d003e9gq94der79nxi.jpeg","/upload/product/d014auzv6qtkpo9jwr.jpeg","/upload/product/d014auzvmuao68bwks.jpeg","/upload/product/d014auzzn3ywqcgkrm.jpeg"]',
          Status: 0,
          BaseUrl: 'base_url',
          CdnUrl: 'cdn_url',
          CreateTime: '2022-01-18T09:35:55+08:00',
          UpdateTime: '2022-01-18T09:35:55+08:00'
        },
        msg: ''
      };
    });
  }
});