import Mock from 'mockjs';
import setupMock from '@/utils/setupMock';
setupMock({
  setup: () => {
    Mock.mock(new RegExp('/api/admin/\\d+$'), 'get', () => {
      console.log('/api/admin/带参数');
      return {
        code: 0,
        data: {
          Id: 5,
          Username: '123@qq.com',
          Password: '',
          Sex: 1,
          Status: 0,
          LastLoginIp: '127.0.0.1:59819',
          LastLoginAt: '2024-06-15 17:16:28',
          CreatedAt: '2024-06-15 09:16:29',
          UpdatedAt: '2024-06-15 09:16:29',
          DeletedAt: null
        },
        msg: ''
      };
    });
    Mock.mock(new RegExp('/api/admin/.*'), 'put', () => {
      return {
        code: 0,
        data: {
          Id: 5,
          Username: '123@qq.com',
          Password: '',
          Sex: 1,
          Status: 0,
          LastLoginIp: '127.0.0.1:59819',
          LastLoginAt: '2024-06-15 17:16:28',
          CreatedAt: '2024-06-15 09:16:29',
          UpdatedAt: '2024-06-15 09:16:29',
          DeletedAt: null
        },
        msg: ''
      };
    });
  }
});